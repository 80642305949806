export default class Toast {
    private static instance: Toast;
    private toastElement: HTMLElement | null;
    private toastMessageElement: HTMLElement | null;

    private constructor() {
        // Create toast element if it doesn't exist
        if (!document.querySelector(".toast")) {
            const toastDiv = this.createToast();
            const body = document.getElementsByTagName("body")[0];
            body?.appendChild(toastDiv);
        }

        this.toastElement = document.querySelector(".toast");
        this.toastMessageElement = document.querySelector(".toast-message");
    }

    private createToast = (): HTMLDivElement => {
        const toastDiv = document.createElement("div");
        toastDiv.className = "toast";
        const toastMessageDiv = document.createElement("div");
        toastMessageDiv.className = "toast-message is-hidden";
        toastMessageDiv.setAttribute("role", "alert");
        toastMessageDiv.setAttribute("aria-atomic", "true");
        toastMessageDiv.setAttribute("aria-hidden", "true");
        toastMessageDiv.setAttribute("aria-live", "assertive");
        toastDiv.appendChild(toastMessageDiv);
        return toastDiv;
    }

    /**
     * Returns the singleton instance of the Toast class.
     * If the instance does not exist, it creates a new one.
     *
     * @returns {Toast} The singleton instance of the Toast class.
     */
    public static getInstance(): Toast {
        if (!Toast.instance) {
            Toast.instance = new Toast();
        }
        return Toast.instance;
    }

    /**
     * Displays a toast message.
     *
     * @param message - The message to display in the toast.
     * @param error - Optional flag to indicate if the toast is an error message. Gives the toast red background color. Defaults to false.
     */
    public show(message: string, error?: boolean): void {
        if (this.toastElement && this.toastMessageElement) {
            this.toastMessageElement.textContent = message;
            this.toastMessageElement.setAttribute("aria-hidden", "false");
            this.toastElement.classList.add("visible");

            error ? this.toastElement.classList.add("error") : this.toastElement.classList.remove("error");

            // Animate toast
            this.animateToast();

            // Hide the toast after 4 seconds
            setTimeout(() => {
                this.toastElement?.classList.remove("visible");
                this.toastMessageElement?.setAttribute("aria-hidden", "true");
            }, 4000);
        }
    }

    private animateToast(): void {
        if (this.toastElement && this.toastMessageElement) {
            this.toastMessageElement.classList.remove("is-hidden");
            this.toastElement.classList.add("is-animating");

            setTimeout(() => {
                this.toastElement?.classList.remove("is-animating");
                this.toastMessageElement?.setAttribute("aria-hidden", "true");
            }, 3000);

            setTimeout(() => {
                this.toastMessageElement?.classList.add("is-hidden");
            }, 4000);
        }
    }
}
